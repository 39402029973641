import api from "../../util/api";
import * as actions from "../../store/modules/campanhaPremiacao/campanha/actions";
import {
  notifyWarn,
  notifySuccess,
  notifyInfo,
} from "../../components/message/message";
import { maskDate, maskMoeda, maskMoeda4new } from "../../util/masks";
import { setLoading } from "../../store/modules/globalLoading/actions";
import moment from "moment";

export async function get(search, pageSize, page) {
  var url = `campanhapremiacao/Paginado?pagina=${page}&registros=${pageSize}`;

  if (search.descricao !== "") url = url + `&descricao=${search.descricao}`;
  if (search.canalVendaId !== "0")
    url = url + `&canalVendaId=${search.canalVendaId}`;
  if (search.tipoParceriaId !== "0")
    url = url + `&tipoParceriaId=${search.tipoParceriaId}`;
  if (search.convenioId !== "0") url = url + `&convenioId=${search.convenioId}`;
  if (search.bancoId !== "0") url = url + `&bancoId=${search.bancoId}`;
  if (search.tipoOperacaoId !== "0")
    url = url + `&tipoOperacaoId=${search.tipoOperacaoId}`;
  if (search.periodo !== "") {
    url =
      url +
      `&dataInicioVig=${search.periodo.substring(
        3,
        7
      )}-${search.periodo.substring(0, 2)}-01`;
    url =
      url +
      (search.periodo.substring(0, 2) !== "02"
        ? `&dataFimVig=${search.periodo.substring(
            3,
            7
          )}-${search.periodo.substring(0, 2)}-30`
        : `&dataFinal=${search.periodo.substring(
            3,
            7
          )}-${search.periodo.substring(0, 2)}-28`);
  }

  setLoading(true);
  const data = await api.get(url);
  setLoading(false);

  if (!data.success) {
    return;
  }

  data.content.registros.forEach((e) => {
    e.periodo = `${e.dataInicio.substring(5, 7)}/${e.dataInicio.substring(
      0,
      4
    )}`;
    e.statusCampanha =
      e.statusCampanha === 0
        ? "Aguardando Aprovação"
        : e.statusCampanha === 1
        ? "Aprovada"
        : e.statusCampanha === 2
        ? "Aguardando Reaprovação"
        : e.statusCampanha === 3
        ? "Encerrada"
        : "Processada para pagamento";
  });

  actions.getItems(data.content.registros);
  actions.getAllItems({
    quantidade: data.content.quantidade,
    quantidadeTotal: data.content.quantidadeTotal,
    totalPaginas: data.content.totalPaginas,
    tamanhoPagina: data.content.tamanhoPagina,
  });
}

export async function getPeriodicidades() {
  const data = await api.get("periodicidade");

  if (!data.success) {
    return;
  }

  actions.setPeriodicidades(data.content);
}

export async function getCanais() {
  const data = await api.get("canal");

  if (!data.success) {
    return;
  }

  actions.setCanais(data.content);
}

export async function getTiposOperacao() {
  const data = await api.get("tipos-operacao");

  if (!data.success) {
    return;
  }

  actions.setTiposOperacao(data.content);
}
export async function getTiposParceria() {
  const data = await api.get("tipoparceira");

  if (!data.success) {
    return;
  }

  actions.setTiposParceria(data.content);
}
export async function getConvenios() {
  const data = await api.get("convenios");

  if (!data.success) {
    return;
  }

  actions.setConvenios(data.content);
}

export async function getCentrosCusto() {
  const data = await api.get("CentrosCusto");

  if (!data.success) {
    return;
  }

  actions.setCentrosCusto(data.content);
}

export async function getProdutos() {
  const data = await api.get("produtos");

  if (!data.success) {
    return;
  }

  actions.getProdutos(data.content);
}

export async function getTiposComissao() {
  const data = await api.get("tipoComissao");

  if (!data.success) {
    return;
  }

  actions.getTiposComissao(data.content);
}

export async function getTiposFuncionario() {
  const data = await api.get("canal");

  if (!data.success) {
    return;
  }

  actions.getTiposFuncionario(data.content);
}

export async function getTiposComissaoProduto(produtoId) {
  const data = await api.get(
    `CampanhaPremiacao/regra-produtividade/${produtoId}/tipos-comissao`
  );

  if (!data.success) {
    return;
  }

  actions.getTiposComissaoProduto(data.content);
}

export async function getGrupos() {
  const data = await api.get("grupos");

  if (!data.success) {
    return;
  }

  actions.getGrupos(data.content);
}

export async function getHierarquias(canalId, tela) {
  const data = await api.get(`hierarquias/hierarquia-canal/${canalId}`);

  if (!data.success) {
    return;
  }

  if (tela === "metaProdutividade") {
    actions.hierarquiaMetaProdutiviadade(data.content);
  } else if (tela === "regraCampanha") {
    actions.hierarquiaRegraCampanha(data.content);
  }
}

export async function getAllHierarquias() {
  const data = await api.get(`hierarquias`);

  if (!data.success) {
    return;
  }

  actions.getHierarquias(data.content);
}

export async function getPeriodos() {
  const data = await api.get("periodo");

  if (!data.success) {
    return;
  }

  actions.getPeriodos(data.content);
}

export async function getTiposPendencia() {
  const data = await api.get("tipo-pendencia");

  if (!data.success) {
    return;
  }

  actions.getTiposPendencia(data.content);
}

export async function getBancos() {
  const data = await api.get("bancos");

  if (!data.success) {
    return;
  }

  actions.setBancos(data.content);
}

export async function getAgenda(idCampanha) {
  const data = await api.get(`CampanhaPremiacao/agenda/${idCampanha}`);

  if (!data.success) {
    return false;
  }

  data.content.forEach((agenda) => {
    agenda.dataExecucao = maskDate(agenda.dataExecucao, "YYYY-MM-DD");
    agenda.dataInicial = maskDate(agenda.dataInicial, "YYYY-MM-DD");
    agenda.dataFim = maskDate(agenda.dataFim, "YYYY-MM-DD");
    agenda.dataPagamento = maskDate(agenda.dataPagamento, "YYYY-MM-DD");
  });

  actions.setItem("campanhaPremiacaoAgenda", data.content);
  return data.content;
}

export async function getById(id) {
  setLoading(true);
  const data = await api.get(`CampanhaPremiacao/${id}`);
  setLoading(false);

  if (!data.success) {
    return;
  }

  let campanhaPremiacaoRegraQualidade =
    data.content.campanhaPremiacaoRegraQualidade;
  campanhaPremiacaoRegraQualidade = campanhaPremiacaoRegraQualidade?.map(
    (regra) => ({
      id: regra.id,
      tipoPendenciaId: regra.tipoPendenciaId,
      periodoId: regra.periodoId ?? null,
      valorMaximo: maskMoeda(Number(regra.valorMaximo).toFixed(2)),
    })
  );

  let campanhaPremiacaoRegras = [];
  data.content.campanhaPremiacaoRegras.forEach((regra) => {
    regra.campanhaPremiacaoMetaProdutividade.forEach((meta) => {
      meta.valorMeta = maskMoeda(Number(meta.valorMeta).toFixed(2));
      meta.valorPremio = maskMoeda4new(Number(meta.valorPremio).toFixed(4)) ?? 0;
      meta.hierarquia = {
        value: meta.hierarquiaId,
        label: "",
      };
    });
    regra.metaGlobal = regra.metaGlobal;
    regra.MetaPrincipal = regra.MetaPrincipal;
    campanhaPremiacaoRegras.push(regra);
  });

  const dataUpdate = {
    id: data.content.id,
    descricao: data.content.descricao,
    periodo: data.content.periodicidadeApuracaoId !== "15115c0b-99ac-4344-af0c-b8e2dca42f68" ? `${data.content.dataInicio.substring(
      5,
      7
    )}/${data.content.dataInicio.substring(0, 4)}` : "",
    periodoInicio: data.content.periodicidadeApuracaoId === "15115c0b-99ac-4344-af0c-b8e2dca42f68" ? maskDate(data.content.dataInicio, "yyyy-MM-DD") : "",
    periodoFim: data.content.periodicidadeApuracaoId === "15115c0b-99ac-4344-af0c-b8e2dca42f68" ? maskDate(data.content.dataFinal, "yyyy-MM-DD") : "",
    dataFinal: maskDate(data.content.dataFinal, "YYYY-MM-DD"),
    diaPagamento:
      data.content.diaPagamento === 0
        ? "DOMINGO"
        : String(data.content.diaPagamento),
    periodicidadeApuracaoId: data.content.periodicidadeApuracaoId,
    codigoSuperacao: data.content.codigoSuperacao,
    codigoProdutividade: data.content.codigoProdutividade,
    tipoFuncionarioId: data.content.canalId,
    codigoRepasse: data.content.codigoRegraRepasse,
    periodicidadePagamentoId: data.content.periodicidadePagamentoId,
    diaApuracao:
      data.content.diaApuracao === 0
        ? "DOMINGO"
        : String(data.content.diaApuracao),
    usuarioAprovadorId: data.content.usuarioAprovadorId,
    descricaoLabel: "",
    descricaoLabelApuracao: "",
    campanhaPremiacaoRegras: campanhaPremiacaoRegras,
    campanhaPremiacaoRegraQualidade,
    campanhaPremiacaoAgenda: data.content.campanhaPremiacaoAgenda,
    hasAproved: data.content.usuarioAprovadorId ? true : false,
    encerrado: data.content.encerrado,
  };

 

  actions.copy(dataUpdate);
  actions.item(dataUpdate);

  return dataUpdate;
}

export async function create(item, itemEvento) {
  if (item.descricao.trim() === "") {
    actions.setInvalid("descricao");
    notifyWarn("Informe a descrição da campanha de premiação");
    return false 
  }

  if (item.periodicidadeApuracaoId === "0") {
    actions.setInvalid("periodicidadeApuracaoId");
    notifyWarn("Selecione um tipo de apuração");
    return false 
  }

  if (item.tipoFuncionarioId === "0") {
    actions.setInvalid("tipoFuncionarioId");
    notifyWarn("Selecione um tipo de funcionário");
    return false 
  }

  //MENSAL
  if (item.periodicidadeApuracaoId === "bfc0c31d-72bb-44ff-b3f2-487bde7a29c3") {
    if (item.periodo === "" || item.periodo.length !== 7) {
      actions.setInvalid("periodo");
      notifyWarn("Informe o período");
      return false 
    }

    if (item.diaApuracao === "" || item.diaApuracao === "0") {
      actions.setInvalid("diaApuracao");
      notifyWarn("Informe o dia da apuração");
      return false 
    }
  }

  //SEMANAL
  if (item.periodicidadeApuracaoId === "15115c0b-99ac-4344-af0c-b8e2dca42f68") {
    if (item.periodoInicio === "" && item.periodoFim === "") {
      actions.setInvalid("periodoInicio");
      actions.setInvalid("periodoFim");
      notifyWarn("Informe o período de vigência");
      return false 
    }
    if (item.periodoInicio === "") {
      actions.setInvalid("periodoInicio");
      notifyWarn("Informe o período início da vigência");
      return false 
    }
    if (item.periodoFim === "") {
      actions.setInvalid("periodoFim");
      notifyWarn("Informe o período fim da vigência");
      return false 
    }
    if (!moment(item.periodoInicio, true).isValid()) {
      actions.setInvalid("periodoInicio");
      notifyWarn("A data de período início é inválida");
      return false 
    }
    if (!moment(item.periodoFim, true).isValid()) {
      actions.setInvalid("periodoFim");
      notifyWarn("A data de período fim é inválida");
      return false 
    }
  
    const periodoInicio = moment(item.periodoInicio).startOf('day');
    const periodoFim = moment(item.periodoFim).startOf('day');
  
    if (periodoFim.isBefore(periodoInicio)) {
      actions.setInvalid("periodoFim");
      notifyWarn("O período fim da vigência não pode ser menor que o período início");
      return false 
    }
    if (item.diaApuracao === "" || item.diaApuracao === "0") {
      actions.setInvalid("diaApuracao");
      notifyWarn("Informe o dia da apuração");
      return false 
    }
  }

  if (item.periodicidadePagamentoId === "0") {
    actions.setInvalid("periodicidadePagamentoId");
    notifyWarn("Selecione um tipo pagamento");
    return false 
  }

  //MENSAL
  if (item.periodicidadeApuracaoId === "bfc0c31d-72bb-44ff-b3f2-487bde7a29c3") {
    if (item.diaPagamento === "0") {
      actions.setInvalid("diaPagamento");
      notifyWarn("Informe o dia do pagamento");
      return false 
    }
  }

  //SEMANAL
  if (item.periodicidadeApuracaoId === "15115c0b-99ac-4344-af0c-b8e2dca42f68") {
    if (item.diaPagamento === "0") {
      actions.setInvalid("diaPagamento");
      notifyWarn("Informe o dia do pagamento");
      return false 
    }
  }

  if (item.codigoRepasse !== "") {
    if (
      item.codigoRepasse === item.codigoSuperacao ||
      item.codigoRepasse === item.codigoProdutividade
    ) {
      notifyWarn(
        "Código de Regra de Repasse não pode ser igual ao Código de Produtividade ou Código de Superação"
      );
      return false 
    }
  }

  const periodoInicio = moment(item.periodoInicio).startOf('day').format('yyyy-MM-DD');
  const periodoFim = moment(item.periodoFim).startOf('day').format('yyyy-MM-DD');

  var newItem = {
    descricao: item.descricao,
    dataInicio: item.periodicidadeApuracaoId === "15115c0b-99ac-4344-af0c-b8e2dca42f68"  ? periodoInicio : `${item.periodo.substring(3, 7)}-${item.periodo.substring(
      0,
      2
    )}-01`,
    dataFinal:
      item.periodicidadeApuracaoId === "15115c0b-99ac-4344-af0c-b8e2dca42f68"  ? periodoFim: item.periodo.substring(0, 2) !== "02"
        ? `${item.periodo.substring(3, 7)}-${item.periodo.substring(0, 2)}-30`
        : `${item.periodo.substring(3, 7)}-${item.periodo.substring(0, 2)}-28`,
    periodicidadeApuracaoId: item.periodicidadeApuracaoId,
    diaApuracao: item.diaApuracao !== "" ? item.diaApuracao : "0",
    periodicidadePagamentoId: item.periodicidadePagamentoId,
    codigoSuperacao: item.codigoSuperacao,
    canalId: item.tipoFuncionarioId,
    codigoProdutividade: item.codigoProdutividade,
    codigoRegraRepasse: item.codigoRepasse,
    diaPagamento: item.diaPagamento !== "" ? item.diaPagamento : "0",
  };

  setLoading(true);
  const data = await api.post("CampanhaPremiacao", newItem);
  setLoading(false);

  if (!data.success) {
    return false;
  }

  actions.setItem("id", data.content.id);

  notifySuccess("Campanha de premiação cadastrada");
  get(
    {
      descricao: "",
      canalVendaId: "0",
      tipoParceriaId: "0",
      convenioId: "0",
      bancoId: "0",
      tipoOperacaoId: "0",
      periodo: "",
    },
    10,
    1
  );
}

export async function aprovarCampanha(campanhaId, agenda) {
  setLoading(true);
  const data = await api.put(`campanhapremiacao/aprovar/${campanhaId}`, agenda);
  setLoading(false);

  if (!data.success) {
    return;
  }

  notifySuccess("Campanha de premiação aprovada");
  get(
    {
      descricao: "",
      canalVendaId: "0",
      tipoParceriaId: "0",
      convenioId: "0",
      bancoId: "0",
      tipoOperacaoId: "0",
      periodo: "",
    },
    10,
    1
  );
}

export async function encerrarCampanha(campanhaId, agenda) {
  setLoading(true);
  const data = await api.put(`campanhapremiacao/encerra/${campanhaId}`, agenda);
  setLoading(false);

  if (!data.success) {
    return;
  }

  notifySuccess("Campanha de premiação encerrada");
  get(
    {
      descricao: "",
      canalVendaId: "0",
      tipoParceriaId: "0",
      convenioId: "0",
      bancoId: "0",
      tipoOperacaoId: "0",
      periodo: "",
    },
    10,
    1
  );
}

export async function remove(id) {
  setLoading(true);
  const data = await api.delete(`campanhapremiacao/${id}`);
  setLoading(false);

  if (!data.success) {
    return;
  }

  notifySuccess("Registro excluído");
  get(
    {
      descricao: "",
      canalVendaId: "0",
      tipoParceriaId: "0",
      convenioId: "0",
      bancoId: "0",
      tipoOperacaoId: "0",
      periodo: "",
    },
    10,
    1
  );
}

export async function update(item, copy) {
  if (JSON.stringify(item) === JSON.stringify(copy)) {
    notifyInfo("Nada foi alterado");
    actions.setActiveTab("Search");
    return false 
  }

  if (item.descricao.trim() === "") {
    actions.setInvalid("descricao");
    notifyWarn("Informe a descrição da campanha de premiação");
    return false 
  }
  
  if (item.periodicidadeApuracaoId === "0") {
    actions.setInvalid("periodicidadeApuracaoId");
    notifyWarn("Selecione um tipo de apuração");
    return false 
  }

  if (item.tipoFuncionarioId === "0") {
    actions.setInvalid("tipoFuncionarioId");
    notifyWarn("Selecione um tipo de funcionário");
    return false 
  }

  //MENSAL
  if(item.periodicidadeApuracaoId === "bfc0c31d-72bb-44ff-b3f2-487bde7a29c3") {
    if (item.periodo === "" || item.periodo.length !== 7) {
      actions.setInvalid("periodo");
      notifyWarn("Informe o período");
      return false 
    }

    if (item.diaApuracao === "" || item.diaApuracao === "0") {
      actions.setInvalid("diaApuracao");
      notifyWarn("Informe o dia da apuração");
      return false 
    }
  }

  //SEMANAL
  if (item.periodicidadeApuracaoId === "15115c0b-99ac-4344-af0c-b8e2dca42f68") {
    if (item.periodoInicio === "" && item.periodoFim === "") {
      actions.setInvalid("periodoInicio");
      actions.setInvalid("periodoFim");
      notifyWarn("Informe o período de vigência");
      return false 
    }
    if (item.periodoInicio === "") {
      actions.setInvalid("periodoInicio");
      notifyWarn("Informe o período início da vigência");
      return false 
    }
    if (item.periodoFim === "") {
      actions.setInvalid("periodoFim");
      notifyWarn("Informe o período fim da vigência");
      return false 
    }
    if (!moment(item.periodoInicio, true).isValid()) {
      actions.setInvalid("periodoInicio");
      notifyWarn("A data de período início é inválida");
      return false 
    }
    if (!moment(item.periodoFim, true).isValid()) {
      actions.setInvalid("periodoFim");
      notifyWarn("A data de período fim é inválida");
      return false 
    }
  
    const periodoInicio = moment(item.periodoInicio).startOf('day');
    const periodoFim = moment(item.periodoFim).startOf('day');
  
    if (periodoFim.isBefore(periodoInicio)) {
      actions.setInvalid("periodoFim");
      notifyWarn("O período fim da vigência não pode ser menor que o período início");
      return false 
    }
    if (item.diaApuracao === "" || item.diaApuracao === "0") {
      actions.setInvalid("diaApuracao");
      notifyWarn("Informe o dia da apuração");
      return false 
    }
  }

  if (item.periodoPagamentoId === "0") {
    actions.setInvalid("pagamento");
    notifyWarn("Selecione um pagamento");
    return false 
  }

  //MENSAL
  if (item.periodicidadeApuracaoId === "bfc0c31d-72bb-44ff-b3f2-487bde7a29c3") {
    if (item.diaPagamento === "0") {
      actions.setInvalid("diaPagamento");
      notifyWarn("Informe o dia do pagamento");
      return false 
    }
  }

  //SEMANAL
  if (item.periodicidadeApuracaoId === "15115c0b-99ac-4344-af0c-b8e2dca42f68") {
    if (item.diaPagamento === "0") {
      actions.setInvalid("diaPagamento");
      notifyWarn("Informe o dia do pagamento");
      return false 
    }
  }

  if (item.codigoRepasse !== "") {
    if (
      item.codigoRepasse === item.codigoSuperacao ||
      item.codigoRepasse === item.codigoProdutividade
    ) {
      notifyWarn(
        "Código de Regra de Repasse não pode ser igual ao Código de Produtividade ou Código de Superação"
      );
      return false 
    }
  }


  const periodoInicio = moment(item.periodoInicio).startOf('day').format('yyyy-MM-DD');
  const periodoFim = moment(item.periodoFim).startOf('day').format('yyyy-MM-DD');

  let body = {
    descricao: item.descricao,
    dataInicio: item.periodicidadeApuracaoId === "15115c0b-99ac-4344-af0c-b8e2dca42f68" ? periodoInicio : `${item.periodo.substring(3, 7)}-${item.periodo.substring(
      0,
      2
    )}-01`,
    dataFinal:
      item.periodicidadeApuracaoId === "15115c0b-99ac-4344-af0c-b8e2dca42f68" ? periodoFim : item.periodo.substring(0, 2) !== "02"
        ? `${item.periodo.substring(3, 7)}-${item.periodo.substring(0, 2)}-30`
        : `${item.periodo.substring(3, 7)}-${item.periodo.substring(0, 2)}-28`,
    periodicidadeApuracaoId: item.periodicidadeApuracaoId,
    diaApuracao: item.diaApuracao !== "" ? item.diaApuracao : "0",
    periodicidadePagamentoId: item.periodicidadePagamentoId,
    codigoSuperacao: item.codigoSuperacao,
    canalId: item.tipoFuncionarioId,
    codigoProdutividade: item.codigoProdutividade,
    codigoRegraRepasse: item.codigoRepasse,
    diaPagamento: item.diaPagamento !== "" ? item.diaPagamento : "0",
  };

  setLoading(true);
  const data = await api.put(`campanhapremiacao/${item.id}`, body);
  setLoading(false);

  if (!data.success) {
    return false;
  }

  notifySuccess("Campanha de premiação atualizada");
  await getById(item.id);
  get(
    {
      descricao: "",
      canalVendaId: "0",
      tipoParceriaId: "0",
      convenioId: "0",
      bancoId: "0",
      tipoOperacaoId: "0",
      periodo: "",
    },
    10,
    1
  );
}

export const insereParametro = async (item) => {
  setLoading(true);
  const response = await api.post("CampanhaPremiacao/parametro", {
    campanhaPremiacaoRegraId: item.campanhaPremiacaoRegraId,
    tipoParametroId: item.tipoParametroId,
    id: item.id,
  })
  setLoading(false);

  if (!response.success) {
    return false
  }

  return response?.content?.id || false
}

export const removeParametro = async ({ id, tipoParametroId }) => {
  setLoading(true);
  const response  = await api.delete(`CampanhaPremiacao/${id}/parametro/${tipoParametroId}`)
  setLoading(false);

  if (!response.success) {
    return false
  }

  return true
}