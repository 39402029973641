import api from "../util/api";
import * as actions from "../store/modules/conferenciaCp/actions";
import { maskMoeda } from "../util/masks";
import { setLoading } from "../store/modules/globalLoading/actions";
import { notifyError, notifySuccess, notifyWarn } from "../components/message/message";
import { guidGenerator } from "../util/guidGenerator";

export async function getContasPagarAgrupada(pesquisa, pageSize, page) {
  var url = `conta-pagar-agrupado/paginado?Pagina=${page}&Quantidade=${pageSize}`;
  if (pesquisa.campanhaPremiacaoId !== "0") {
    url = url + `&CampanhaPremiacaoId=${pesquisa.campanhaPremiacaoId}`;
  }
  if (pesquisa.empresaId !== "0") {
    url = url + `&EmpresaId=${pesquisa.empresaId}`;
  }
  if (pesquisa.tipoRegraId !== "0") {
    url = url + `&TipoRegraId=${pesquisa.tipoRegraId}`;
  }
  if (pesquisa.dataAceito !== "") {
    url = url + `&DataAceito=${pesquisa.dataAceito}`;
  }
  if (pesquisa.sistemaId !== "0") {
    url = url + `&SistemaId=${pesquisa.sistemaId}`;
  }
  if (pesquisa.statusId !== "0") {
    url = url + `&StatusId=${pesquisa.statusId}`;
  }

  setLoading(true);
  const data = await api.get(url);
  setLoading(false);
  if (!data.success) {
    return;
  }

  data.content.registros.forEach((item) => {
    item.dataAceito = new Date(Date.parse(item.dataAceito));
    item.valor =
      item.valor < 0
        ? `- ${maskMoeda(item.valor?.toFixed(2))}`
        : maskMoeda(item.valor?.toFixed(2));
  });

  actions.setContasPagarAgrupado(data.content.registros);
  actions.setPagination({
    quantidade: data.content.quantidade,
    quantidadeTotal: data.content.quantidadeTotal,
    totalPaginas: data.content.totalPaginas,
    tamanhoPagina: data.content.tamanhoPagina,
  });
}

export async function getCampanhaPremiacao() {
  const data = await api.get("CampanhaPremiacao/campanhas-processadas");

  if (!data.success) {
    return;
  }

  actions.setOptionsSelect("campanhas", data.content);
}

export async function getEmpresas() {
  const data = await api.get("EmpresaFuncionario");

  if (!data.success) {
    return;
  }

  actions.setOptionsSelect("empresas", data.content);
}

export async function getTiposPagamento() {
  const data = await api.get("CampanhaPremiacao/tipos-regras");

  if (!data.success) {
    return;
  }

  actions.setOptionsSelect("tiposPagamento", data.content);
}

export async function getSistemas() {
  const data = await api.get("contas-pagar/sistemas");

  if (!data.success) {
    return;
  }

  actions.setOptionsSelect("sistemas", data.content);
}

export async function getStatus() {
  const data = await api.get("contas-pagar/status");

  if (!data.success) {
    return;
  }

  actions.setOptionsSelect("status", data.content);
}

export async function getFiltros() {
  setLoading(true);
  await Promise.all([
    getCampanhaPremiacao(),
    getEmpresas(),
    getTiposPagamento(),
    getSistemas(),
    getStatus(),
  ]);
  setLoading(false);
}

export async function aprovarContas(ids) {
  if(ids.length === 0){
    notifyWarn("Selecione ao menos uma conta para aprovar");
    return;
  }

  const promises = ids.map(id => 
    api.put(`conta-pagar-agrupado/aprova-contas-pagar-agrupado/${id}`)
  );
  setLoading(true);
  const results = await Promise.all(promises);
  setLoading(false);

  let successCount = 0;
  let failureCount = 0;

  results.forEach(result => {
    if (result.success) {
      successCount++;
    } else {
      failureCount++;
    }
  });

  if(successCount > 0){
    notifySuccess(`${successCount} ${successCount === 1 ? 'conta aprovada' : 'contas aprovadas'} com sucesso`);
  }
  if (failureCount > 0) {
    notifyError(`Erro ao aprovar ${failureCount} ${failureCount === 1 ? 'conta' : 'contas'}`);
  }
}

export async function estornarContas(ids) {
  if(ids.length === 0){
    notifyWarn("Selecione ao menos uma conta para estornar");
    return;
  }

  const promises = ids.map(id => 
    api.put(`conta-pagar-agrupado/estorna-contas-pagar-agrupado/${id}`)
  );
  setLoading(true);
  const results = await Promise.all(promises);
  setLoading(false);

  let successCount = 0;
  let failureCount = 0;

  results.forEach(result => {
    if (result.success) {
      successCount++;
    } else {
      failureCount++;
    }
  });

  if(successCount > 0) {
    notifySuccess(`${successCount} ${successCount === 1 ? 'conta estornada' : 'contas estornadas'} com sucesso`);
  }
  if (failureCount > 0) {
    notifyError(`Erro ao estornar ${failureCount} ${failureCount === 1 ? 'conta' : 'contas'}`);
  }
}

export async function aprovarConta(id) {
  setLoading(true);
  const data = await api.put(`conta-pagar-agrupado/aprova-contas-pagar-agrupado/${id}`);
  setLoading(false);

  if (!data.success) {
    return;
  }

  notifySuccess("Conta aprovada com sucesso");
}

export async function estornarConta(id) {
  setLoading(true);
  const data = await api.put(`conta-pagar-agrupado/estorna-contas-pagar-agrupado/${id}`)
  setLoading(false);

  if (!data.success) {
    return;
  }

  notifySuccess("Conta estornada com sucesso");
}

export async function getContaPagarAgrupadoItens(id) {
  setLoading(true);
  const data = await api.get(`conta-pagar-agrupado/${id}/itens`);
  setLoading(false);

  if (!data.success) {
    return;
  }

  data.content.forEach((item) => {
    item.valor = maskMoeda(item.valor?.toFixed(2))
  })

  actions.setContaPagarAgrupadoItens(data.content);
}

export async function alteraSistemaContaPagar(id, sistemaId) {
  setLoading(true);
  const data = await api.put(`conta-pagar-agrupado/altera-sistema-conta-pagar/${id}/sistema/${sistemaId}`)
  setLoading(false);

  if (!data.success) {
    return false;
  }

  notifySuccess("Conta atualizada com sucesso");
  return true
}

export async function downloadPlanilha(id) {
  setLoading(true);
  const data = await api.get(`conta-pagar-agrupado/${id}/planilha`);
  setLoading(false);

  if (!data.success) {
    return false;
  }

  actions.setDownload({
    arquivoBase64: data.content,
    nomeArquivo: `${guidGenerator()}.csv`,
  });

  return true;
}
